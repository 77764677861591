// import React from 'react';
// import { Line } from 'react-chartjs-2';
// import Chart from 'chart.js';

// const TaskCompletionChart = ({ taskCompletionCounts }) => {
//   // Extract task names and ensure they are unique
//   const uniqueTaskLabels = Array.from(new Set(taskCompletionCounts.map(task => task.taskName)));

//   const normalizedTaskLabels = uniqueTaskLabels.map(label => label.toLowerCase());

//   const taskCompletionMap = taskCompletionCounts.reduce((acc, task) => {
//     const normalizedName = task.taskName.toLowerCase();
//     acc[normalizedName] = task.completedCount;
//     return acc;
//   }, {});

//   const data = normalizedTaskLabels.map(label => taskCompletionMap[label] || 0);

//   const chartData = {
//     labels: uniqueTaskLabels,
//     datasets: [
//       {
//         label: 'Task Completions',
//         data: data,
//         borderColor: '#ffffff',
//         backgroundColor: '#674eff',
//         borderWidth: 2,
//         fill: true,
//         pointRadius: 6,
//         pointBackgroundColor: '#ffffff',
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     scales: {
//       xAxes: [
//         {
//           display: true,
//           ticks: {
//             display: false, // Hide ticks if needed
//           },
//           scaleLabel: {
//             display: true,
//             labelString: 'Tasks',
//             fontSize: 18,
//             padding: 10,
//             fontColor: '#ffffff', // Set the color of the x-axis label to white
//           },
//         },
//       ],
//       yAxes: [
//         {
//           display: true,
//           ticks: {
//             fontSize: 16,
//             beginAtZero: true,
//             fontColor: '#ffffff', // Set the color of y-axis ticks to white
//           },
//           scaleLabel: {
//             display: true,
//             labelString: 'Users',
//             fontSize: 18,
//             fontColor: '#ffffff', // Set the color of the y-axis label to white
//           },
//         },
//       ],
//     },
//     legend: {
//       position: 'bottom',
//       labels: {
//         fontSize: 18,
//         boxWidth: 45,
//         padding: 20,
//         fontColor: '#ffffff',
//       },
//     },
//     title: {
//       display: false,
//     },
//   };
  

//   return (
//     <div style={{ width: '100%', height: "50%"  }}>
//       <Line data={chartData} options={options}  />
//     </div>
//   );
// };

// export default TaskCompletionChart;









import React from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js';

// Function to generate a random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const TaskCompletionChart = ({ taskCompletionCounts }) => {
  // Extract task names and ensure they are unique
  const uniqueTaskLabels = Array.from(new Set(taskCompletionCounts.map(task => task.taskName)));

  const normalizedTaskLabels = uniqueTaskLabels.map(label => label.toLowerCase());

  const taskCompletionMap = taskCompletionCounts.reduce((acc, task) => {
    const normalizedName = task.taskName.toLowerCase();
    acc[normalizedName] = task.completedCount;
    return acc;
  }, {});

  const data = normalizedTaskLabels.map(label => taskCompletionMap[label] || 0);

  // Generate a color for each task dynamically
  const barColors = uniqueTaskLabels.map(() => getRandomColor());

  const chartData = {
    labels: uniqueTaskLabels,
    datasets: [
      {
        label: 'Task Completions',
        data: data,
        backgroundColor: barColors, 
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Tasks',
            fontSize: 18,
            padding: 10,
            fontColor: '#ffffff',
          },
        },
      ],
      yAxes: [
        {
          display: true,
          ticks: {
            fontSize: 16,
            beginAtZero: true,
            fontColor: '#ffffff',
          },
          scaleLabel: {
            display: true,
            labelString: 'Users',
            fontSize: 18,
            fontColor: '#ffffff',
          },
        },
      ],
    },
    legend: {
      position: 'bottom',
      labels: {
        fontSize: 18,
        boxWidth: 45,
        padding: 20,
        fontColor: '#ffffff',
      },
    },
    title: {
      display: false,
    },
    // animation: {
    //   onComplete: function() {
    //     const ctx = this.chart.ctx;
    //     ctx.font = 'bold 12px Arial';
    //     ctx.textAlign = 'center';
    //     ctx.textBaseline = 'bottom';

    //     this.data.datasets.forEach((dataset, i) => {
    //       const meta = this.chart.getDatasetMeta(i);
    //       meta.data.forEach((bar, index) => {
    //         const taskName = this.data.labels[index];
    //         ctx.fillStyle = '#ffffff'; // Set the color of the task names
    //         ctx.fillText(taskName, bar._model.x, bar._model.y - 5);
    //       });
    //     });
    //   },
    // },
  };

  return (
    <div style={{ width: '100%', height: '50%' }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default TaskCompletionChart;

