import React, { useState, useEffect } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Card,
  CircularProgress,
  IconButton,
  Tooltip,
  InputAdornment,
  Switch
} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { backendServices } from '../services/backendService'

const AdminDashboard = () => {
  const [admins, setAdmins] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [showPassword, setShowPassword] = useState(false)
  const [selectedAdmin, setSelectedAdmin] = useState(null)
  const [editAdmin, setEditAdmin] = useState({
    email: '',
    username: '',
    password: '',
    role: 'admin',
    isActive: true
  })
  const [newAdmin, setNewAdmin] = useState({
    email: '',
    username: '',
    password: '',
    role: 'admin'
  })

  useEffect(() => {
    fetchAdmins(currentPage)
  }, [currentPage])

  const fetchAdmins = async page => {
    setLoading(true)
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/admin/getadmins`
      )

      setAdmins(response.data.admins)
      setCurrentPage(response.data.currentPage)
      setTotalPages(response.data.totalPages)
    } catch (error) {
      console.error('Error fetching admins:', error)
      toast.error('Error fetching admins')
    } finally {
      setLoading(false)
    }
  }

  const handleAddAdmin = async e => {
    e.preventDefault()
    try {
      const response = await backendServices.Post(
        `${process.env.REACT_APP_BASE_URL}/admin/add`,
        {
          email: newAdmin.email,
          username: newAdmin.username,
          password: newAdmin.password,
          role: newAdmin.role
        }
      )

      if (response.status === 200) {
        setOpenDialog(false)
        fetchAdmins(currentPage)
        setNewAdmin({ email: '', username: '', password: '', role: 'admin' })
        toast.success('Admin added successfully')
      } else {
        throw new Error(response.data.message || 'Failed to add admin')
      }
    } catch (error) {
      console.error('Error adding admin:', error)
      toast.error(error.message || 'Error adding admin')
    }
  }


  const handleEditAdmin = async e => {
    e.preventDefault()
    try {
      const response = await backendServices.Put(
        `${process.env.REACT_APP_BASE_URL}/admin/editAdmin/${selectedAdmin._id}`,
        editAdmin
      )

      if (response.status === 200) {
        setOpenEditDialog(false)
        fetchAdmins(currentPage)
        toast.success('Admin updated successfully')
      }
    } catch (error) {
      toast.error(error.message || 'Error updating admin')
    }
  }

  const handleStatusToggle = async (adminId, currentStatus) => {
    try {
      const response = await backendServices.Put(
        `${process.env.REACT_APP_BASE_URL}/admin/editAdmin/${adminId}`,
        {
          isActive: !currentStatus
        }
      )

      if (response.status === 200) {
        fetchAdmins(currentPage)
        toast.success('Admin status updated successfully')
      }
    } catch (error) {
      toast.error('Error updating admin status')
    }
  }

  const openEdit = admin => {
    setSelectedAdmin(admin)
    setEditAdmin({
      email: admin.email,
      username: admin.username,
      role: admin.role,
      isActive: admin.isActive,
      password: ''
    })
    setOpenEditDialog(true)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const formatDate = dateString => {
    return new Date(dateString).toLocaleString()
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>
      <AppBar
        position='static'
        sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
      >
        <Toolbar>
          <Typography variant='h4'>Admin Management</Typography>
        </Toolbar>
      </AppBar>

      <Card
        style={{
          background: 'black',
          color: 'white',
          margin: '20px',
          height: 'calc(100vh - 40px)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', margin: '30px' }}
        >
          <Button
            variant='contained'
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
          >
            Add Admin
          </Button>
        </Box>

        {loading ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='100vh'
            marginTop='-50px'
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
<TableContainer component={Paper} style={{ background: 'black', color: 'white' }}>
              <Table>
                <TableHead>
                  <TableRow style={{background:"#212121"}}>
                    <TableCell style={{ color: 'white' }}>Email</TableCell>
                    <TableCell style={{ color: 'white' }}>Username</TableCell>
                    <TableCell style={{ color: 'white' }}>Password</TableCell>
                    <TableCell style={{ color: 'white' }}>Role</TableCell>
                    <TableCell style={{ color: 'white' }}>Status</TableCell>
                    <TableCell style={{ color: 'white' }}>Created</TableCell>
                    <TableCell style={{ color: 'white' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {admins.map(admin => (
                    <TableRow key={admin._id}>
                      <TableCell style={{ color: 'white' }}>{admin.email}</TableCell>
                      <TableCell style={{ color: 'white' }}>{admin.username}</TableCell>
                      <TableCell style={{ color: 'white' }}>
                        {admin.password.slice(0, 6)}...{admin.password.slice(-6)}
                      </TableCell>
                      <TableCell style={{ color: 'white' }}>{admin.role}</TableCell>
                      <TableCell>
                        <Switch
                          checked={admin.isActive}
                          onChange={() => handleStatusToggle(admin._id, admin.isActive)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell style={{ color: 'white' }}>{formatDate(admin.createdAt)}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => openEdit(admin)} color="primary">
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}
            >
              <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                Previous
              </Button>
              <Typography sx={{ mx: 2, color: 'white' }}>
                Page {currentPage} of {totalPages}
              </Typography>
              <Button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </Box>
          </>
        )}

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          fullWidth
          maxWidth='sm'
          PaperProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              boxShadow: 'none',
              border: '1px solid gray'
            }
          }}
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' }
          }}
        >
          <DialogTitle style={{ background: 'black' }}>
            Add New Admin
          </DialogTitle>
          <form onSubmit={handleAddAdmin}>
            <DialogContent style={{ background: 'black' }}>
              <TextField
                autoFocus
                margin='dense'
                label='Email'
                type='email'
                fullWidth
                required
                value={newAdmin.email}
                onChange={e =>
                  setNewAdmin({ ...newAdmin, email: e.target.value })
                }
                style={{ marginBottom: '16px' }}
              />
              <TextField
                margin='dense'
                label='Username'
                fullWidth
                required
                value={newAdmin.username}
                onChange={e =>
                  setNewAdmin({ ...newAdmin, username: e.target.value })
                }
                style={{ marginBottom: '16px' }}
              />

              <TextField
                margin='dense'
                label='Password'
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required
                value={newAdmin.password}
                onChange={e =>
                  setNewAdmin({ ...newAdmin, password: e.target.value })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge='end'
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                style={{ marginBottom: '16px' }}
              />
              <TextField
                margin='dense'
                label='Role'
                fullWidth
                value={newAdmin.role}
                InputProps={{
                  readOnly: true
                }}
                style={{ marginBottom: '16px' }}
              />
            </DialogContent>
            <DialogActions style={{ background: 'black' }}>
              <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
              <Button type='submit' variant='contained'>
                Add Admin
              </Button>
            </DialogActions>
          </form>
        </Dialog>
         {/* Add Edit Admin Dialog */}
         <Dialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          fullWidth
          maxWidth='sm'
          PaperProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              boxShadow: 'none',
              border: '1px solid gray'
            }
          }}
        >
          <DialogTitle style={{ background: 'black' }}>Edit Admin</DialogTitle>
          <form onSubmit={handleEditAdmin}>
            <DialogContent style={{ background: 'black' }}>
              <TextField
                margin='dense'
                label='Email'
                type='email'
                fullWidth
                value={editAdmin.email}
                onChange={e => setEditAdmin({ ...editAdmin, email: e.target.value })}
                style={{ marginBottom: '16px' }}
              />
              <TextField
                margin='dense'
                label='Username'
                fullWidth
                value={editAdmin.username}
                onChange={e => setEditAdmin({ ...editAdmin, username: e.target.value })}
                style={{ marginBottom: '16px' }}
              />
              <TextField
                margin='dense'
                label='New Password (optional)'
                type={showPassword ? 'text' : 'password'}
                fullWidth
                value={editAdmin.password}
                onChange={e => setEditAdmin({ ...editAdmin, password: e.target.value })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                style={{ marginBottom: '16px' }}
              />
              <TextField
                margin='dense'
                label='Role'
                fullWidth
                value={editAdmin.role}
                InputProps={{
                  readOnly: true
                }}
                style={{ marginBottom: '16px' }}
              />
            </DialogContent>
            <DialogActions style={{ background: 'black' }}>
              <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
              <Button type='submit' variant='contained'>
                Update Admin
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Card>
      <ToastContainer />
    </>
  )
}

export default AdminDashboard
