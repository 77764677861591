// import React, { useState, useEffect } from 'react'
// import {
//   Container,
//   Card,
//   Typography,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   IconButton,
//   Menu,
//   MenuItem,
//   ListItemIcon,
//   ListItemText,
//   Pagination,
//   Stack,
//   Typography as MUI_Typography
// } from '@mui/material'
// import {
//   Edit as EditIcon,
//   Delete as DeleteIcon,
//   MoreVert as MoreVertIcon
// } from '@mui/icons-material'
// import { backendServices } from '../services/backendService'

// // BoostList Component
// const BoostList = ({ refreshTrigger, onEdit, onDelete }) => {
//   const [boosts, setBoosts] = useState([])
//   const [page, setPage] = useState(1)
//   const [rowsPerPage] = useState(10)
//   const [anchorEl, setAnchorEl] = useState(null)
//   const [selectedBoost, setSelectedBoost] = useState(null)
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
//   const [boostToDelete, setBoostToDelete] = useState(null)

//   useEffect(() => {
//     fetchBoosts()
//   }, [refreshTrigger])

//   const fetchBoosts = async () => {
//     try {
//       const response = await backendServices.Get(
//         `${process.env.REACT_APP_BASE_URL}/boots/allBoosts`
//       )
//       if (response.data) {
//         setBoosts(response.data.data)
//       }
//     } catch (error) {
//       console.error('Error fetching boosts:', error)
//     }
//   }

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage)
//   }

//   const handleOpenMenu = (event, boost) => {
//     setAnchorEl(event.currentTarget)
//     setSelectedBoost(boost)
//   }

//   const handleCloseMenu = () => {
//     setAnchorEl(null)
//     setSelectedBoost(null)
//   }

//   const handleEdit = () => {
//     onEdit(selectedBoost)
//     handleCloseMenu()
//   }

//   const handleDelete = () => {
//     setBoostToDelete(selectedBoost)
//     setOpenConfirmDialog(true)
//     handleCloseMenu()
//   }

//   const confirmDelete = () => {
//     onDelete(boostToDelete._id, boostToDelete.boostName)
//     setOpenConfirmDialog(false)
//     setBoostToDelete(null)
//   }

//   const cancelDelete = () => {
//     setOpenConfirmDialog(false)
//     setBoostToDelete(null)
//   }

//   const paginatedBoosts = boosts.slice(
//     (page - 1) * rowsPerPage,
//     page * rowsPerPage
//   )

//   return (
//     <>
//       <Card style={{ background: 'black' }}>
//         <TableContainer>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell align='left'>Boost Name</TableCell>
//                 <TableCell align='left'>Levels</TableCell>
//                 <TableCell align='left'>Values</TableCell>
//                 <TableCell align='left'>Costs</TableCell>
//                 <TableCell align='left'>Actions</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {paginatedBoosts.map(boost => (
//                 <TableRow hover key={boost._id} tabIndex={-1}>
//                   <TableCell align='left'>{boost.boostName}</TableCell>
//                   <TableCell align='left'>{boost.levels.join(', ')}</TableCell>
//                   <TableCell align='left'>{boost.values.join(', ')}</TableCell>
//                   <TableCell align='left'>{boost.costs.join(', ')}</TableCell>
//                   <TableCell align='left'>
//                     <IconButton
//                       aria-controls='boost-menu'
//                       aria-haspopup='true'
//                       onClick={event => handleOpenMenu(event, boost)}
//                     >
//                       <MoreVertIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <Stack spacing={2} direction='row' justifyContent='center' mt={2}>
//           <Pagination
//             count={Math.ceil(boosts.length / rowsPerPage)}
//             page={page}
//             onChange={handleChangePage}
//             color='primary'
//             showFirstButton
//             showLastButton
//           />
//         </Stack>
//         {/* <Menu
//           id='boost-menu'
//           anchorEl={anchorEl}
//           open={Boolean(anchorEl)}
//           onClose={handleCloseMenu}
//           sx={{
//             '& .MuiMenu-paper': {
//               backgroundColor: 'black'
//             }
//           }}
//         >
//           <MenuItem onClick={handleEdit}>
//             <ListItemIcon>
//               <EditIcon />
//             </ListItemIcon>
//             <ListItemText primary='Edit' />
//           </MenuItem>
//           <MenuItem onClick={handleDelete}>
//             <ListItemIcon>
//               <DeleteIcon />
//             </ListItemIcon>
//             <ListItemText primary='Delete' />
//           </MenuItem>
//         </Menu> */}
//         <Dialog
//           open={openConfirmDialog}
//           onClose={cancelDelete}
//           aria-labelledby='confirm-dialog-title'
//           aria-describedby='confirm-dialog-description'
//           style={{ background: 'black' }}
//         >
//           <DialogTitle
//             id='confirm-dialog-title'
//             style={{ background: 'black' }}
//           >
//             Confirm Deletion
//           </DialogTitle>
//           <DialogContent style={{ background: 'black' }}>
//             <Typography id='confirm-dialog-description'>
//               Are you sure you want to delete the boost of type:{' '}
//               {boostToDelete?.boostName}?
//             </Typography>
//           </DialogContent>
//           <DialogActions style={{ background: 'black' }}>
//             <Button onClick={cancelDelete} color='primary'>
//               Cancel
//             </Button>
//             <Button onClick={confirmDelete} color='error' autoFocus>
//               Delete
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Card>
//     </>
//   )
// }

// // AddBoost Component
// const AddBoost = ({ open, onClose, onBoostAdded }) => {
//   const initialBoostData = {
//     boostName: '',
//     levels: ['', '', '', '', ''],
//     values: ['', '', '', '', ''],
//     costs: ['', '', '', '', '']
//   }

//   const [boostData, setBoostData] = useState(initialBoostData)

//   useEffect(() => {
//     if (open) {
//       setBoostData(initialBoostData)
//     }
//   }, [open])

//   const handleChange = (e, index, field) => {
//     const newData = [...boostData[field]]
//     newData[index] = e.target.value === '' ? '' : Number(e.target.value)
//     setBoostData({
//       ...boostData,
//       [field]: newData
//     })
//   }

//   const handleSubmit = async e => {
//     e.preventDefault()
//     try {
//       const cleanedBoostData = {
//         ...boostData,
//         levels: boostData.levels.filter(level => level !== ''),
//         values: boostData.values.filter(value => value !== ''),
//         costs: boostData.costs.filter(cost => cost !== '')
//       }
//       const response = await backendServices.Post(
//         `${process.env.REACT_APP_BASE_URL}/boots/addBoost`,
//         cleanedBoostData
//       )
//       if (response.data) {
//         onBoostAdded()
//         onClose()
//       }
//     } catch (error) {
//       console.error('Error adding boost:', error)
//       alert('Error adding boost. Please try again.')
//     }
//   }

//   return (
//     <Dialog open={open} onClose={onClose} style={{ background: 'black' }}>
//       <DialogTitle style={{ background: 'black' }}>Add New Boost</DialogTitle>
//       <DialogContent style={{ background: 'black' }}>
//         <TextField
//           autoFocus
//           margin='dense'
//           name='boostName'
//           label='Boost Name'
//           type='text'
//           fullWidth
//           variant='standard'
//           value={boostData.boostName}
//           onChange={e =>
//             setBoostData({ ...boostData, boostName: e.target.value })
//           }
//         />
//         {['levels', 'values', 'costs'].map(field => (
//           <div key={field}>
//             <MUI_Typography variant='subtitle1'>
//               {field.charAt(0).toUpperCase() + field.slice(1)}
//             </MUI_Typography>
//             {[0, 1, 2, 3, 4].map(index => (
//               <TextField
//                 key={`${field}-${index}`}
//                 margin='dense'
//                 label={`${field} ${index + 1}`}
//                 type='number'
//                 fullWidth
//                 variant='standard'
//                 value={boostData[field][index]}
//                 onChange={e => handleChange(e, index, field)}
//               />
//             ))}
//           </div>
//         ))}
//       </DialogContent>
//       <DialogActions style={{ background: 'black' }}>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button onClick={handleSubmit}>Add Boost</Button>
//       </DialogActions>
//     </Dialog>
//   )
// }

// // EditBoost Component
// const EditBoost = ({ open, onClose, boost, onBoostUpdated }) => {
//   const [boostData, setBoostData] = useState({
//     boostName: '',
//     levels: [],
//     values: [],
//     costs: []
//   })

//   useEffect(() => {
//     if (boost) {
//       setBoostData(boost)
//     }
//   }, [boost])

//   const handleChange = (e, index, field) => {
//     const newData = [...boostData[field]]
//     newData[index] = Number(e.target.value)
//     setBoostData({
//       ...boostData,
//       [field]: newData
//     })
//   }

//   const handleSubmit = async e => {
//     e.preventDefault()
//     try {
//       const response = await backendServices.Put(
//         `${process.env.REACT_APP_BASE_URL}/boots/editBoosts/${boost._id}`,
//         boostData
//       )
//       if (response.data) {
//         onBoostUpdated()
//         onClose()
//       }
//     } catch (error) {
//       console.error('Error updating boost:', error)
//     }
//   }

//   return (
//     <Dialog open={open} onClose={onClose} style={{ background: 'black' }}>
//       <DialogTitle style={{ background: 'black' }}>Edit Boost</DialogTitle>
//       <DialogContent style={{ background: 'black' }}>
//         <TextField
//           autoFocus
//           margin='dense'
//           name='boostName'
//           label='Boost Name'
//           type='text'
//           fullWidth
//           variant='standard'
//           value={boostData.boostName}
//           onChange={e =>
//             setBoostData({ ...boostData, boostName: e.target.value })
//           }
//         />
//         {['levels', 'values', 'costs'].map(field => (
//           <div key={field}>
//             <MUI_Typography variant='subtitle1'>
//               {field.charAt(0).toUpperCase() + field.slice(1)}
//             </MUI_Typography>
//             {[0, 1, 2, 3, 4].map(index => (
//               <TextField
//                 key={`${field}-${index}`}
//                 margin='dense'
//                 label={`${field} ${index + 1}`}
//                 type='number'
//                 fullWidth
//                 variant='standard'
//                 value={boostData[field][index] || ''}
//                 onChange={e => handleChange(e, index, field)}
//               />
//             ))}
//           </div>
//         ))}
//       </DialogContent>
//       <DialogActions style={{ background: 'black' }}>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button onClick={handleSubmit}>Update Boost</Button>
//       </DialogActions>
//     </Dialog>
//   )
// }

// // BoostPage Component
// const BoostPage = () => {
//   const [openAddDialog, setOpenAddDialog] = useState(false)
//   const [openEditDialog, setOpenEditDialog] = useState(false)
//   const [selectedBoost, setSelectedBoost] = useState(null)
//   const [refreshTrigger, setRefreshTrigger] = useState(false)

//   const handleOpenAddDialog = () => {
//     setOpenAddDialog(true)
//   }

//   const handleCloseAddDialog = () => {
//     setOpenAddDialog(false)
//   }

//   const handleOpenEditDialog = boost => {
//     setSelectedBoost(boost)
//     setOpenEditDialog(true)
//   }

//   const handleCloseEditDialog = () => {
//     setOpenEditDialog(false)
//     setSelectedBoost(null)
//   }

//   const handleBoostAdded = () => {
//     setRefreshTrigger(!refreshTrigger)
//     handleCloseAddDialog()
//   }

//   const handleBoostUpdated = () => {
//     setRefreshTrigger(!refreshTrigger)
//     handleCloseEditDialog()
//   }

//   const handleBoostDeleted = async (boostId, boostName) => {
//     try {
//       const response = await backendServices.Delete(
//         `${process.env.REACT_APP_BASE_URL}/boots/deleteBoosts/${boostId}`
//       )
//       if (response.data) {
//         setRefreshTrigger(!refreshTrigger)
//         alert(`Boost ${boostName} deleted successfully.`)
//       }
//     } catch (error) {
//       console.error('Error deleting boost:', error)
//       alert('Error deleting boost. Please try again.')
//     }
//   }

//   return (
//     <Container>
//       <Stack
//         direction='row'
//         justifyContent='space-between'
//         alignItems='center'
//         mb={3}
//       >
//         <Typography variant='h4' gutterBottom>
//           Boosts Management
//         </Typography>
//         {/* <Button
//           variant='contained'
//           color='primary'
//           onClick={handleOpenAddDialog}
//         >
//           Add New Boost
//         </Button> */}
//       </Stack>
//       <BoostList
//         refreshTrigger={refreshTrigger}
//         onEdit={handleOpenEditDialog}
//         onDelete={handleBoostDeleted}
//       />
//       <AddBoost
//         open={openAddDialog}
//         onClose={handleCloseAddDialog}
//         onBoostAdded={handleBoostAdded}
//       />
//       <EditBoost
//         open={openEditDialog}
//         onClose={handleCloseEditDialog}
//         boost={selectedBoost}
//         onBoostUpdated={handleBoostUpdated}
//       />
//     </Container>
//   )
// }

// export default BoostPage


















// import React, { useState, useEffect } from 'react';
// import {
//   Container,
//   Card,
//   Typography,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   IconButton,
//   Menu,
//   MenuItem,
//   ListItemIcon,
//   ListItemText,
//   Pagination,
//   Stack,
//   Typography as MUI_Typography
// } from '@mui/material';
// import {
//   Edit as EditIcon,
//   Delete as DeleteIcon,
//   MoreVert as MoreVertIcon
// } from '@mui/icons-material';
// import { backendServices } from '../services/backendService';

// // BoostList Component
// const BoostList = ({ refreshTrigger, onEdit, onDelete }) => {
//   const [boosts, setBoosts] = useState([]);
//   const [page, setPage] = useState(1);
//   const [rowsPerPage] = useState(10);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedBoost, setSelectedBoost] = useState(null);
//   const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
//   const [boostToDelete, setBoostToDelete] = useState(null);

//   useEffect(() => {
//     fetchBoosts();
//   }, [refreshTrigger]);

//   const fetchBoosts = async () => {
//     try {
//       const response = await backendServices.Get(
//         `${process.env.REACT_APP_BASE_URL}/boots/allBoosts`
//       );
//       if (response.data) {
//         setBoosts(response.data.data);
//       }
//     } catch (error) {
//       console.error('Error fetching boosts:', error);
//     }
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleOpenMenu = (event, boost) => {
//     setAnchorEl(event.currentTarget);
//     setSelectedBoost(boost);
//   };

//   const handleCloseMenu = () => {
//     setAnchorEl(null);
//     setSelectedBoost(null);
//   };

//   const handleEdit = () => {
//     onEdit(selectedBoost);
//     handleCloseMenu();
//   };

//   const handleDelete = () => {
//     setBoostToDelete(selectedBoost);
//     setOpenConfirmDialog(true);
//     handleCloseMenu();
//   };

//   const confirmDelete = () => {
//     onDelete(boostToDelete._id);
//     setOpenConfirmDialog(false);
//     setBoostToDelete(null);
//   };

//   const cancelDelete = () => {
//     setOpenConfirmDialog(false);
//     setBoostToDelete(null);
//   };

//   const paginatedBoosts = boosts.slice(
//     (page - 1) * rowsPerPage,
//     page * rowsPerPage
//   );

//   const renderBoostData = (boost, field) => {
//     return (
//       <>
//         <strong>{field}:</strong><br />
//         Levels: {boost[field].levels.join(', ')}<br />
//         Values: {boost[field].value.join(', ')}<br />
//         Costs: {boost[field].cost.join(', ')}
//       </>
//     );
//   };

//   return (
//     <>
//       <Card style={{ background: 'black' }}>
//         <TableContainer>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell align='left'>Multi Tap</TableCell>
//                 <TableCell align='left'>Energy Limit</TableCell>
//                 <TableCell align='left'>Recharging Speed</TableCell>
//                 <TableCell align='left'>Actions</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {paginatedBoosts.map(boost => (
//                 <TableRow hover key={boost._id} tabIndex={-1}>
//                   <TableCell align='left'>{renderBoostData(boost, 'multiTap')}</TableCell>
//                   <TableCell align='left'>{renderBoostData(boost, 'energyLimit')}</TableCell>
//                   <TableCell align='left'>{renderBoostData(boost, 'rechargingSpeed')}</TableCell>
//                   <TableCell align='left'>
//                     <IconButton
//                       aria-controls='boost-menu'
//                       aria-haspopup='true'
//                       onClick={event => handleOpenMenu(event, boost)}
//                     >
//                       <MoreVertIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <Stack spacing={2} direction='row' justifyContent='center' mt={2}>
//           <Pagination
//             count={Math.ceil(boosts.length / rowsPerPage)}
//             page={page}
//             onChange={handleChangePage}
//             color='primary'
//             showFirstButton
//             showLastButton
//           />
//         </Stack>
//         <Menu
//           id='boost-menu'
//           anchorEl={anchorEl}
//           open={Boolean(anchorEl)}
//           onClose={handleCloseMenu}
//           sx={{
//             '& .MuiMenu-paper': {
//               backgroundColor: 'black'
//             }
//           }}
//         >
//           <MenuItem onClick={handleEdit}>
//             <ListItemIcon>
//               <EditIcon />
//             </ListItemIcon>
//             <ListItemText primary='Edit' />
//           </MenuItem>
//           <MenuItem onClick={handleDelete}>
//             <ListItemIcon>
//               <DeleteIcon />
//             </ListItemIcon>
//             <ListItemText primary='Delete' />
//           </MenuItem>
//         </Menu>
//         <Dialog
//           open={openConfirmDialog}
//           onClose={cancelDelete}
//           aria-labelledby='confirm-dialog-title'
//           aria-describedby='confirm-dialog-description'
//           style={{ background: 'black' }}
//         >
//           <DialogTitle id='confirm-dialog-title' style={{ background: 'black' }}>
//             Confirm Deletion
//           </DialogTitle>
//           <DialogContent style={{ background: 'black' }}>
//             <Typography id='confirm-dialog-description'>
//               Are you sure you want to delete this boost?
//             </Typography>
//           </DialogContent>
//           <DialogActions style={{ background: 'black' }}>
//             <Button onClick={cancelDelete} color='primary'>
//               Cancel
//             </Button>
//             <Button onClick={confirmDelete} color='error' autoFocus>
//               Delete
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Card>
//     </>
//   );
// };

// // AddBoost Component
// const AddBoost = ({ open, onClose, onBoostAdded }) => {
//   const initialBoostData = {
//     multiTap: { levels: ['', '', '', '', ''], value: ['', '', '', '', ''], cost: ['', '', '', '', ''] },
//     energyLimit: { levels: ['', '', '', '', ''], value: ['', '', '', '', ''], cost: ['', '', '', '', ''] },
//     rechargingSpeed: { levels: ['', '', '', '', ''], value: ['', '', '', '', ''], cost: ['', '', '', '', ''] }
//   };

//   const [boostData, setBoostData] = useState(initialBoostData);

//   useEffect(() => {
//     if (open) {
//       setBoostData(initialBoostData);
//     }
//   }, [open]);

//   const handleChange = (e, index, field, subField) => {
//     const newData = { ...boostData };
//     newData[field][subField][index] = e.target.value === '' ? '' : Number(e.target.value);
//     setBoostData(newData);
//   };

//   const handleSubmit = async e => {
//     e.preventDefault();
//     try {
//       const cleanedBoostData = Object.keys(boostData).reduce((acc, field) => {
//         acc[field] = {
//           levels: boostData[field].levels.filter(level => level !== ''),
//           value: boostData[field].value.filter(value => value !== ''),
//           cost: boostData[field].cost.filter(cost => cost !== '')
//         };
//         return acc;
//       }, {});

//       const response = await backendServices.Post(
//         `${process.env.REACT_APP_BASE_URL}/boots/addBoost`,
//         cleanedBoostData
//       );
//       if (response.data) {
//         onBoostAdded();
//         onClose();
//       }
//     } catch (error) {
//       console.error('Error adding boost:', error);
//       alert('Error adding boost. Please try again.');
//     }
//   };

//   return (
//     <Dialog open={open} onClose={onClose} style={{ background: 'black' }}>
//       <DialogTitle style={{ background: 'black' }}>Add New Boost</DialogTitle>
//       <DialogContent style={{ background: 'black' }}>
//         {Object.keys(boostData).map(field => (
//           <div key={field}>
//             <MUI_Typography variant='subtitle1'>
//               {field.charAt(0).toUpperCase() + field.slice(1)}
//             </MUI_Typography>
//             {['levels', 'value', 'cost'].map(subField => (
//               <div key={`${field}-${subField}`}>
//                 <MUI_Typography variant='subtitle2'>{subField}</MUI_Typography>
//                 {[0, 1, 2, 3, 4].map(index => (
//                   <TextField
//                     key={`${field}-${subField}-${index}`}
//                     margin='dense'
//                     label={`${subField} ${index + 1}`}
//                     type='number'
//                     fullWidth
//                     variant='standard'
//                     value={boostData[field][subField][index]}
//                     onChange={e => handleChange(e, index, field, subField)}
//                   />
//                 ))}
//               </div>
//             ))}
//           </div>
//         ))}
//       </DialogContent>
//       <DialogActions style={{ background: 'black' }}>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button onClick={handleSubmit}>Add Boost</Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// // EditBoost Component
// const EditBoost = ({ open, onClose, boost, onBoostUpdated }) => {
//   const [boostData, setBoostData] = useState({
//     multiTap: { levels: [], value: [], cost: [] },
//     energyLimit: { levels: [], value: [], cost: [] },
//     rechargingSpeed: { levels: [], value: [], cost: [] }
//   });

//   useEffect(() => {
//     if (boost) {
//       setBoostData(boost);
//     }
//   }, [boost]);

//   const handleChange = (e, index, field, subField) => {
//     const newData = { ...boostData };
//     newData[field][subField][index] = Number(e.target.value);
//     setBoostData(newData);
//   };

//   const handleSubmit = async e => {
//     e.preventDefault();
//     try {
//       const response = await backendServices.Put(
//         `${process.env.REACT_APP_BASE_URL}/boots/editBoosts/${boost._id}`,
//         boostData
//       );
//       if (response.data) {
//         onBoostUpdated();
//         onClose();
//       }
//     } catch (error) {
//       console.error('Error updating boost:', error);
//     }
//   };

//   return (
//     <Dialog open={open} onClose={onClose} style={{ background: 'black' }}>
//       <DialogTitle style={{ background: 'black' }}>Edit Boost</DialogTitle>
//       <DialogContent style={{ background: 'black' }}>
//         {Object.keys(boostData).map(field => (
//           <div key={field}>
//             <MUI_Typography variant='subtitle1'>
//               {field.charAt(0).toUpperCase() + field.slice(1)}
//             </MUI_Typography>
//             {['levels', 'value', 'cost'].map(subField => (
//               <div key={`${field}-${subField}`}>
//                 <MUI_Typography variant='subtitle2'>{subField}</MUI_Typography>
//                 {boostData[field][subField].map((value, index) => (
//                   <TextField
//                     key={`${field}-${subField}-${index}`}
//                     margin='dense'
//                     label={`${subField} ${index + 1}`}
//                     type='number'
//                     fullWidth
//                     variant='standard'
//                     value={value}
//                     onChange={e => handleChange(e, index, field, subField)}
//                   />
//                 ))}
//               </div>
//             ))}
//           </div>
//         ))}
//       </DialogContent>
//       <DialogActions style={{ background: 'black' }}>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button onClick={handleSubmit}>Update Boost</Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// // BoostPage Component
// const BoostPage = () => {
//   const [openAddDialog, setOpenAddDialog] = useState(false);
//   const [openEditDialog, setOpenEditDialog] = useState(false);
//   const [selectedBoost, setSelectedBoost] = useState(null);
//   const [refreshTrigger, setRefreshTrigger] = useState(false);

//   const handleOpenAddDialog = () => {
//     setOpenAddDialog(true);
//   };

//   const handleCloseAddDialog = () => {
//     setOpenAddDialog(false);
//   };

//   const handleOpenEditDialog = boost => {
//     setSelectedBoost(boost);
//     setOpenEditDialog(true);
//   };

//   const handleCloseEditDialog = () => {
//     setOpenEditDialog(false);
//     setSelectedBoost(null);
//   };

//   const handleBoostAdded = () => {
//     setRefreshTrigger(!refreshTrigger);
//     handleCloseAddDialog();
//   };

//   const handleBoostUpdated = () => {
//     setRefreshTrigger(!refreshTrigger);
//     handleCloseEditDialog();
//   };

//   const handleBoostDeleted = async (boostId) => {
//     try {
//       const response = await backendServices.Delete(
//         `${process.env.REACT_APP_BASE_URL}/boots/deleteBoosts/${boostId}`
//       );
//       if (response.data) {
//         setRefreshTrigger(!refreshTrigger);
//         alert(`Boost deleted successfully.`);
//       }
//     } catch (error) {
//       console.error('Error deleting boost:', error);
//       alert('Error deleting boost. Please try again.');
//     }
//   };

//   return (
//     <Container>
//       <Stack
//         direction='row'
//         justifyContent='space-between'
//         alignItems='center'
//         mb={3}
//       >
//         <Typography variant='h4' gutterBottom>
//           Boosts Management
//         </Typography>
//         <Button
//           variant='contained'
//           color='primary'
//           onClick={handleOpenAddDialog}
//         >
//           Add New Boost
//         </Button>
//       </Stack>
//       <BoostList
//         refreshTrigger={refreshTrigger}
//         onEdit={handleOpenEditDialog}
//         onDelete={handleBoostDeleted}
//       />
//       <AddBoost
//         open={openAddDialog}
//         onClose={handleCloseAddDialog}
//         onBoostAdded={handleBoostAdded}
//       />
//       <EditBoost
//         open={openEditDialog}
//         onClose={handleCloseEditDialog}
//         boost={selectedBoost}
//         onBoostUpdated={handleBoostUpdated}
//       />
//     </Container>
//   );
// };

// export default BoostPage;














//this is static boost code 
import React, { useState, useEffect } from 'react';
import {
  Container,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Stack,
  CircularProgress,
  Box
} from '@mui/material';
import { backendServices } from '../services/backendService';

// BoostList Component
const BoostList = () => {
  const [boosts, setBoosts] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchBoosts();
  }, []);

  const fetchBoosts = async () => {
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/boots/allBoosts`
      );
      if (response.data) {
        setBoosts(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching boosts:', error);
    } finally {
      // setLoading(false); // Hide loader after fetch completes or fails
      setTimeout(() => {
        setLoading(false);
      }, 1000)
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const paginatedBoosts = boosts.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const renderBoostRows = (boost) => {
    const boostTypes = ['multiTap', 'energyLimit', 'rechargingSpeed'];
    return boostTypes.map((type) => (
      <TableRow key={`${boost._id}-${type}`}>
        <TableCell>{type.charAt(0).toUpperCase() + type.slice(1)}</TableCell>
        <TableCell>{boost[type].levels.join(', ')}</TableCell>
        <TableCell>{boost[type].value.join(', ')}</TableCell>
        <TableCell>{boost[type].cost.join(', ')}</TableCell>
      </TableRow>
    ));
  };

  return (
    <Card style={{ background: 'black', color: 'white' }}>
       {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" marginTop="-50px">
          <CircularProgress />
        </Box>
      ) : (
        <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{background:"#212121"}}>
              <TableCell style={{color:"#fc9f0d"}}>Boost Name</TableCell>
              <TableCell style={{color:"#fc9f0d"}}>Levels</TableCell>
              <TableCell style={{color:"#fc9f0d"}}>Values</TableCell>
              <TableCell style={{color:"#fc9f0d"}}>Costs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedBoosts.map((boost) => renderBoostRows(boost))}
          </TableBody>
        </Table>
      </TableContainer></>)}
      <Stack spacing={2} direction="row" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(boosts.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
          showFirstButton
          showLastButton
        />
      </Stack>
    </Card>
  );
};

// BoostPage Component
const BoostPage = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Boosts Management
      </Typography>
          <BoostList />
    </Container>
  );
};

export default BoostPage;
