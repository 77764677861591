import PropTypes from "prop-types";
import LogoImg from "../assets/sugarBoy Logo.svg";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return <img src={LogoImg} style={{width:"250px", marginLeft:"70px"}} alt="Sugar Boy Bot" />;
}
