import React from 'react';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import Chart from 'chart.js';

const DashChartData = ({ chartData = {} }) => {
  // Generate dynamic dates based on the current date and the number of data points
  const generateDates = (numDays) => {
    const dates = [];
    const today = dayjs();
    for (let i = numDays - 1; i >= 0; i--) {
      dates.push(today.subtract(i, 'day').format('D-MMM-YYYY')); // Format date as 1-Aug-2024
    }
    return dates;
  };

  // Assume we have data for the last 15 days
  const numDays = 15;
  const dates = generateDates(numDays);

  // Sample data for the last `numDays`
  const data = {
    labels: dates,
    datasets: [
      {
        label: 'Daily Users',
        data: Array(numDays).fill(chartData.dailyActiveUsers || 0),
        borderColor: '#510087',
        backgroundColor: 'rgba(81, 0, 135, 0.3)',
        fill: true,
        lineTension: 0.4,
      },
      {
        label: 'Taps',
        data: Array(numDays).fill(chartData.totalTouches || 0),
        borderColor: '#00b8ff',
        backgroundColor: 'rgba(0, 184, 255, 0.3)',
        fill: true,
        lineTension: 0.4,
      },
      {
        label: 'Tasks Completed',
        data: Array(numDays).fill(chartData.totalTasksCompleted || 0),
        borderColor: '#d1105a',
        backgroundColor: 'rgba(209, 16, 90, 0.3)',
        fill: true,
        lineTension: 0.4,
      },
      {
        label: 'User accounts connected',
        data: Array(numDays).fill(chartData.totalVerifiedUser || 0),
        borderColor: '#ffd93d',
        backgroundColor: 'rgba(255, 217, 61, 0.3)',
        fill: true,
        lineTension: 0.4,
      },
      {
        label: 'Inactive Users',
        data: Array(numDays).fill(chartData.InactiveUsers || 0),
        borderColor: '#2e518a',
        backgroundColor: 'rgba(46, 81, 138, 0.3)',
        fill: true,
        lineTension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      labels: {
        fontColor: '#ffffff',
      },
    },
    scales: {
      xAxes: [
        {
          type: 'category',
          labels: dates,
          ticks: {
            fontColor: '#ffffff',
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: '#ffffff',
          },
          gridLines: {
            color: 'rgba(255, 255, 255, 0.1)',
          },
        },
      ],
    },
    tooltips: {
      mode: 'index',
      intersect: false,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      titleFontColor: '#ffffff',
      bodyFontColor: '#ffffff',
      footerFontColor: '#ffffff',
    },
  };

  return (
    <div style={{ height: '350px', width: '100%' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default DashChartData;
