import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TablePagination,
  Switch,
  Menu,
  MenuItem,
  IconButton,
  CircularProgress,
  Card,
  AppBar,
  Toolbar
} from '@mui/material'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon,
  ContentCopy as ContentCopyIcon
} from '@mui/icons-material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { backendServices } from '../services/backendService'

const PartnerManager = () => {
  const [partners, setPartners] = useState([])
  const [open, setOpen] = useState(false)
  const [newPartner, setNewPartner] = useState({
    partnerName: '',
    joiningRewards: 0
  })
  const [editPartner, setEditPartner] = useState(null)
  const [filters, setFilters] = useState({ partnerName: '' })
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [totalRecords, setTotalRecords] = useState(0)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [partnerToDelete, setPartnerToDelete] = useState(null)
  const [actionAnchorEl, setActionAnchorEl] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchPartners()
  }, [page, rowsPerPage, filters])
  

  const fetchPartners = async () => {
    try {      
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/partners/getPartners?page=${page+1}&limit=${rowsPerPage}&partnerName=${filters.partnerName}`,
      )
      setPartners(response.data.data)
      setTotalRecords(response.data.totalRecords)
    } catch (error) {
      console.error('Error fetching partners:', error)
    }finally {
      setLoading(false)
    }
  }

  const handleOpen = () => {
    setEditPartner(null)
    setNewPartner({ partnerName: '', joiningRewards: 0 })
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setEditPartner(null)
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    if (editPartner) {
      setEditPartner({ ...editPartner, [name]: value })
    } else {
      setNewPartner({ ...newPartner, [name]: value })
    }
  }

  const handleFilterChange = e => {
    const { name, value } = e.target
    setFilters({ ...filters, [name]: value })
    setPage(0)
  }

  const handleAddPartner = async () => {
    try {
      await backendServices.Post(
        `${process.env.REACT_APP_BASE_URL}/partners/add`,
        newPartner
      )
      handleClose()
      fetchPartners()
    } catch (error) {
      console.error('Error adding partner:', error)
    }
  }

  const handleEditOpen = partner => {
    setEditPartner(partner)
    setOpen(true)
  }

  const handleEditPartner = async () => {
    try {
      await backendServices.Put(
        `${process.env.REACT_APP_BASE_URL}/partners/edit/${editPartner._id}`,
        editPartner
      )
      handleClose()
      fetchPartners()
    } catch (error) {
      console.error('Error editing partner:', error)
    }
  }

  const handleDeleteConfirmOpen = partner => {
    setPartnerToDelete(partner)
    setDeleteConfirmOpen(true)
  }

  const handleDeleteConfirmClose = () => {
    setPartnerToDelete(null)
    setDeleteConfirmOpen(false)
  }

  const handleDeletePartner = async () => {
    try {
      await backendServices.Delete(
        `${process.env.REACT_APP_BASE_URL}/partners/delete/${partnerToDelete._id}`
      )
      handleDeleteConfirmClose()
      fetchPartners()
    } catch (error) {
      console.error('Error deleting partner:', error)
    }
  }

  const handleToggleDisabled = async partner => {
    try {
      await backendServices.Put(
        `${process.env.REACT_APP_BASE_URL}/partners/edit/${partner._id}`,
        {
          ...partner,
          isDisabled: !partner.isDisabled
        }
      )
      fetchPartners()
    } catch (error) {
      console.error('Error toggling partner disabled status:', error)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleActionMenuOpen = (event, partnerId) => {
    setActionAnchorEl({ ...actionAnchorEl, [partnerId]: event.currentTarget })
  }

  const handleActionMenuClose = partnerId => {
    setActionAnchorEl({ ...actionAnchorEl, [partnerId]: null })
  }

  const handleCopyLink = link => {
    toast.success('Link copied successfully!')
  }

  return (
    <>
      <AppBar
        position='static'
        sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
      >
        <Toolbar>
          <Typography variant='h4'>Partner Manager</Typography>
        </Toolbar>
      </AppBar>
      <Card
        style={{
          background: 'black',
          color: 'white',
          margin: '20px',
          height: 'calc(100vh - 40px)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ m: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              mb: 2
            }}
          >
            <Button variant='contained' onClick={handleOpen}>
              Add Partners
            </Button>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              name='partnerName'
              label='Filter by Partner Code'
              variant='outlined'
              value={filters.partnerName}
              onChange={handleFilterChange}
            />
          </Box>

          <TableContainer
            component={Paper}
            sx={{ maxHeight: 440, background: 'black' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ backgroundColor: '#212121', color: '#fc9f0d' }}
                  >
                    Partner Name
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: '#212121', color: '#fc9f0d' }}
                  >
                    Partner Code
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: '#212121', color: '#fc9f0d' }}
                  >
                    Link
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: '#212121', color: '#fc9f0d' }}
                  >
                    Joining Reward
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: '#212121', color: '#fc9f0d' }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: '#212121', color: '#fc9f0d' }}
                  >
                    Created At
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center" sx={{ color: 'white' }}>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}> */}
                  <CircularProgress color="primary" />
                {/* </Box> */}
                    </TableCell>
                  </TableRow>
                ) : error ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center" sx={{ color: 'white' }}>
                      {error}
                    </TableCell>
                  </TableRow>
                ) : partners.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center" sx={{ color: 'white' }}>
                      No data found
                    </TableCell>
                  </TableRow>
                ) : (
                  partners.map(partner => (
                    <TableRow key={partner._id}>
                      <TableCell sx={{ color: 'white' }}>
                        {partner.partnerName}
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        {partner.partnerCode}
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={{ color: 'white', mr: 1 }}>
                            {partner.link}
                          </Typography>
                          <CopyToClipboard
                            text={partner.link}
                            onCopy={() => handleCopyLink(partner.link)}
                          >
                            <IconButton>
                              <ContentCopyIcon sx={{ color: 'white' }} />
                            </IconButton>
                          </CopyToClipboard>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        {partner.joiningRewards}
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Switch
                            checked={!partner.isDisabled}
                            onChange={() => handleToggleDisabled(partner)}
                            color='primary'
                          />
                          <Typography sx={{ ml: 1 }}>
                            {partner.isDisabled ? 'Disabled' : 'Enabled'}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ color: 'white' }}>
                        {new Date(partner.createdAt).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component='div'
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              bgcolor: 'black',
              color: 'white',
              '& .MuiTablePagination-actions': {
                color: 'white'
              }
            }}
          />
        </Box>

        <Dialog
          open={open}
          onClose={handleClose}
          style={{ background: 'black' }}
        >
          <DialogTitle style={{ background: 'black' }}>
            {editPartner ? 'Edit Partner' : 'Add New Partner'}
          </DialogTitle>
          <DialogContent style={{ background: 'black' }}>
            <TextField
              autoFocus
              margin='dense'
              name='partnerName'
              label='Partner Name'
              type='text'
              fullWidth
              variant='outlined'
              value={
                editPartner ? editPartner.partnerName : newPartner.partnerName
              }
              onChange={handleInputChange}
            />
            <TextField
              margin='dense'
              name='joiningRewards'
              label='Joining Rewards'
              type='number'
              fullWidth
              variant='outlined'
              value={
                editPartner
                  ? editPartner.joiningRewards
                  : newPartner.joiningRewards
              }
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions style={{ background: 'black' }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={editPartner ? handleEditPartner : handleAddPartner}
            >
              {editPartner ? 'Save' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteConfirmOpen}
          onClose={handleDeleteConfirmClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title' style={{ background: 'black' }}>
            {'Confirm Delete'}
          </DialogTitle>
          <DialogContent style={{ background: 'black' }}>
            <DialogContentText id='alert-dialog-description'>
              Are you sure you want to delete this partner?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ background: 'black' }}>
            <Button onClick={handleDeleteConfirmClose}>Cancel</Button>
            <Button
              onClick={handleDeletePartner}
              autoFocus
              style={{ color: 'red' }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
      <ToastContainer />
    </>
  )
}

export default PartnerManager
