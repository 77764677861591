import React, { useState, useEffect } from 'react'
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  TableSortLabel,
  TextField,
  Pagination,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Paper,
  CircularProgress,
  Box
} from '@mui/material'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material'
import { backendServices } from '../services/backendService'

// Define the table head for leagues
const TABLE_HEAD = [
  { id: 'logo', label: 'Logo', alignRight: false },
  { id: 'name', label: 'League Name', alignRight: false },
  { id: 'currentleague', label: 'Current League', alignRight: false },
  { id: 'leagueFrom', label: 'League From', alignRight: false },
  { id: 'leagueTo', label: 'League To', alignRight: false },
  { id: 'levelUpBonus', label: 'Level Up Bonus', alignRight: false },
  // { id: 'actions', label: 'Actions', alignRight: false }
]

// Component to render the toolbar for filtering leagues
const LeagueListToolbar = ({ filterName, onFilterName }) => (
  <Stack direction='row' spacing={2} style={{ padding: '20px' }}>
    <TextField
      label='Filter by League Name'
      variant='outlined'
      size='medium'
      value={filterName}
      onChange={onFilterName}
    />
  </Stack>
)

// Component to render the list of leagues with pagination and sorting
const LeagueList = ({ refreshTrigger, onEdit, onDelete }) => {
  const [leagues, setLeagues] = useState([])
  const [filterName, setFilterName] = useState('')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(1)
  const [rowsPerPage] = useState(10)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedLeague, setSelectedLeague] = useState(null)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchLeagues()
  }, [refreshTrigger])

  const fetchLeagues = async () => {
    try {
      const response = await backendServices.Get(
        `${process.env.REACT_APP_BASE_URL}/league/getLeagues`
      )
      if (response.data.success) {
        setLeagues(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching leagues:', error)
    } finally {
      // setLoading(false); // Hide loader after fetch completes or fails
      setTimeout(() => {
        setLoading(false);
      }, 1000)
    }
  }

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleFilterByName = event => {
    setFilterName(event.target.value)
    setPage(1)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleOpenMenu = (event, league) => {
    setAnchorEl(event.currentTarget)
    setSelectedLeague(league)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
    setSelectedLeague(null)
  }

  const handleEdit = () => {
    onEdit(selectedLeague)
    handleCloseMenu()
  }

  const handleDelete = () => {
    onDelete(selectedLeague)
    handleCloseMenu()
  }

  const filteredLeagues = leagues.filter(league =>
    league.name.toLowerCase().includes(filterName.toLowerCase())
  )

  const sortedLeagues = filteredLeagues.sort((a, b) => {
    const isAsc = order === 'asc'
    if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1
    if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1
    return 0
  })

  const paginatedLeagues = sortedLeagues.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  )

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        background: 'black'
      }}
    >
      <LeagueListToolbar
        filterName={filterName}
        onFilterName={handleFilterByName}
      />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" marginTop="-50px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Paper sx={{ width: '100%', overflow: 'auto', background: 'black' }}>
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map(headCell => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ backgroundColor: '#212121', color: '#fc9f0d' }}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => handleRequestSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedLeagues.map(row => (
                    <TableRow hover key={row._id} tabIndex={-1}>
                      <TableCell align='left'>
                        <img
                          src={row.leagueThumbnail}
                          alt={row.name}
                          style={{ width: '50px', height: '50px' }}
                        />
                      </TableCell>
                      <TableCell align='left'>{row.name}</TableCell>
                      <TableCell align='left'>{row.currentleague}</TableCell>
                      <TableCell align='left'>{row.leagueFrom}</TableCell>
                      <TableCell align='left'>{row.leagueTo}</TableCell>
                      <TableCell align='left'>{row.levelUpBonus}</TableCell>
                      {/* <TableCell align='left'>
                        <IconButton
                          aria-controls='league-menu'
                          aria-haspopup='true'
                          onClick={event => handleOpenMenu(event, row)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id='league-menu'
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                          sx={{
                            '& .MuiMenu-paper': {
                              backgroundColor: 'black'
                            }
                          }}
                        >
                          <MenuItem onClick={handleEdit}>
                            <ListItemIcon>
                              <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary='Edit' />
                          </MenuItem>
                          <MenuItem onClick={handleDelete}>
                            <ListItemIcon>
                              <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary='Delete' />
                          </MenuItem>
                        </Menu>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper></>)}
      <Stack spacing={2} direction='row' justifyContent='center' mt={2}>
        <Pagination
          count={Math.ceil(sortedLeagues.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          color='primary'
          showFirstButton
          showLastButton
        />
      </Stack>
    </Card>
  )
}

// Component to render the dialog for adding a new league
const AddLeague = ({ open, onClose, onLeagueAdded }) => {
  const initialLeagueData = {
    name: '',
    subTitle: '',
    currentleague: '',
    leagueFrom: '',
    leagueTo: '',
    levelUpBonus: '',
    leagueThumbnail: '',
    leagueLogoUrl: ''
  }

  const [leagueData, setLeagueData] = useState(initialLeagueData)

  const handleChange = e => {
    const { name, value } = e.target
    setLeagueData({
      ...leagueData,
      [name]: value
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const response = await backendServices.Post(
        `${process.env.REACT_APP_BASE_URL}/league/addLeauge`,
        leagueData
      )
      if (response.data) {
        onLeagueAdded(response.data)
        setLeagueData(initialLeagueData) // Reset the input fields
        onClose()
      }
    } catch (error) {
      console.error('Error adding league:', error)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
          color: 'white',
          boxShadow: 'none', // Remove the shadow
          border: '1px solid gray',
        }
      }}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } // Lighten the backdrop
      }}>
      <DialogTitle style={{ background: 'black' }}>Add League</DialogTitle>
      <DialogContent style={{ background: 'black' }}>
        <TextField
          autoFocus
          margin='dense'
          name='name'
          label='League Name'
          type='text'
          fullWidth
          variant='standard'
          value={leagueData.name}
          onChange={handleChange}
        />
        <TextField
          autoFocus
          margin='dense'
          name='subTitle'
          label='League subTitle'
          type='text'
          fullWidth
          variant='standard'
          value={leagueData.subTitle}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='currentleague'
          label='Current League'
          type='text'
          fullWidth
          variant='standard'
          value={leagueData.currentleague}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='leagueThumbnail'
          label='leagueThumbnail'
          type='text'
          fullWidth
          variant='standard'
          value={leagueData.leagueThumbnail}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='leagueLogoUrl'
          label='leagueLogoUrl'
          type='text'
          fullWidth
          variant='standard'
          value={leagueData.leagueLogoUrl}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='leagueFrom'
          label='League From'
          type='text'
          fullWidth
          variant='standard'
          value={leagueData.leagueFrom}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='leagueTo'
          label='League To'
          type='text'
          fullWidth
          variant='standard'
          value={leagueData.leagueTo}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='levelUpBonus'
          label='Level Up Bonus'
          type='text'
          fullWidth
          variant='standard'
          value={leagueData.levelUpBonus}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions style={{ background: 'black' }}>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color='primary'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// Component to render the dialog for editing a league
const EditLeague = ({ open, onClose, leagueData, onLeagueUpdated }) => {
  const [updatedData, setUpdatedData] = useState(leagueData)

  useEffect(() => {
    setUpdatedData(leagueData)
  }, [leagueData])

  const handleChange = e => {
    const { name, value } = e.target
    setUpdatedData({
      ...updatedData,
      [name]: value
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const response = await backendServices.Put(
        `${process.env.REACT_APP_BASE_URL}/league/${updatedData._id}`,
        updatedData
      )
      if (response.data) {
        onLeagueUpdated(response.data)
        onClose()
      }
    } catch (error) {
      console.error('Error updating league:', error)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
          color: 'white',
          boxShadow: 'none', // Remove the shadow
          border: '1px solid gray',
        }
      }}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } // Lighten the backdrop
      }}>
      <DialogTitle style={{ background: 'black' }}>Edit League</DialogTitle>
      <DialogContent style={{ background: 'black' }}>
        <TextField
          autoFocus
          margin='dense'
          name='name'
          label='League Name'
          type='text'
          fullWidth
          variant='standard'
          value={updatedData.name}
          onChange={handleChange}
        />
        <TextField
          autoFocus
          margin='dense'
          name='subTitle'
          label='League subTitle'
          type='text'
          fullWidth
          variant='standard'
          value={leagueData.subTitle}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='currentleague'
          label='Current League'
          type='text'
          fullWidth
          variant='standard'
          value={updatedData.currentleague}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='leagueThumbnail'
          label='leagueThumbnail'
          type='text'
          fullWidth
          variant='standard'
          value={leagueData.leagueThumbnail}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='leagueLogoUrl'
          label='leagueLogoUrl'
          type='text'
          fullWidth
          variant='standard'
          value={leagueData.leagueLogoUrl}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='leagueFrom'
          label='League From'
          type='text'
          fullWidth
          variant='standard'
          value={updatedData.leagueFrom}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='leagueTo'
          label='League To'
          type='text'
          fullWidth
          variant='standard'
          value={updatedData.leagueTo}
          onChange={handleChange}
        />
        <TextField
          margin='dense'
          name='levelUpBonus'
          label='Level Up Bonus'
          type='text'
          fullWidth
          variant='standard'
          value={updatedData.levelUpBonus}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions style={{ background: 'black' }}>
        <Button onClick={onClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color='primary'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// Component to render the confirmation dialog
const ConfirmationDialog = ({ open, onClose, onConfirm, message }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="sm"
    PaperProps={{
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Semi-transparent background
        color: 'white',
        boxShadow: 'none', // Remove the shadow
        border: '1px solid gray',
      }
    }}
    BackdropProps={{
      style: { backgroundColor: 'rgba(0, 0, 0, 0.4)' } // Lighten the backdrop
    }}>
    <DialogTitle style={{ background: 'black' }}>Confirm Action</DialogTitle>
    <DialogContent style={{ background: 'black' }}>{message}</DialogContent>
    <DialogActions style={{ background: 'black' }}>
      <Button onClick={onClose} color='primary'>
        Cancel
      </Button>
      <Button
        onClick={() => {
          onConfirm()
          onClose()
        }}
        color='error'
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
)

// Main component to manage leagues
const LeagueManager = () => {
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [selectedLeague, setSelectedLeague] = useState(null)
  const [refreshTrigger, setRefreshTrigger] = useState(false)
  const [leagueToDelete, setLeagueToDelete] = useState(null)

  const handleOpenAddDialog = () => setOpenAddDialog(true)
  const handleCloseAddDialog = () => setOpenAddDialog(false)

  const handleOpenEditDialog = league => {
    setSelectedLeague(league)
    setOpenEditDialog(true)
  }
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false)
    setSelectedLeague(null)
  }

  const handleOpenConfirmDialog = league => {
    setLeagueToDelete(league)
    setOpenConfirmDialog(true)
  }
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false)
    setLeagueToDelete(null)
  }

  const handleLeagueAdded = () => {
    setRefreshTrigger(!refreshTrigger)
  }

  const handleLeagueUpdated = () => {
    setRefreshTrigger(!refreshTrigger)
  }

  const handleDeleteLeague = async () => {
    try {
      await backendServices.Delete(
        `${process.env.REACT_APP_BASE_URL}/league/${leagueToDelete._id}`
      )
      setRefreshTrigger(!refreshTrigger)
      handleCloseConfirmDialog()
    } catch (error) {
      console.error('Error deleting league:', error)
    }
  }

  return (
    //     <Container>
    //       <Stack
    //         direction='row'
    //         justifyContent='space-between'
    //         alignItems='center'
    //         mb={3}
    //       >
    //         <Typography variant='h4' gutterBottom>
    //           League Management
    //         </Typography>
    //         <Button
    //           variant='contained'
    //           color='primary'
    //           onClick={handleOpenAddDialog}
    //         >
    //           Add League
    //         </Button>
    //       </Stack>
    //       <LeagueList
    //         refreshTrigger={refreshTrigger}
    //         onEdit={handleOpenEditDialog}
    //         onDelete={handleOpenConfirmDialog}
    //       />
    //       {selectedLeague && (
    //         <EditLeague
    //           open={openEditDialog}
    //           onClose={handleCloseEditDialog}
    //           leagueData={selectedLeague}
    //           onLeagueUpdated={handleLeagueUpdated}
    //         />
    //       )}
    //       <AddLeague
    //         open={openAddDialog}
    //         onClose={handleCloseAddDialog}
    //         onLeagueAdded={handleLeagueAdded}
    //       />
    //       <ConfirmationDialog
    //         open={openConfirmDialog}
    //         onClose={handleCloseConfirmDialog}
    //         onConfirm={handleDeleteLeague}
    //         message='Are you sure you want to delete this league?'
    //       />
    //     </Container>
    //   )
    // }
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden'
      }}
    >
      <Container sx={{ flex: '0 0 auto' }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          mb={3}
        >
          <Typography variant='h4' gutterBottom>
            League Management
          </Typography>
          {/* <Button
            variant='contained'
            color='primary'
            onClick={handleOpenAddDialog}
          >
            Add League
          </Button> */}
        </Stack>
      </Container>
      <Box sx={{ flex: '1 1 auto', overflow: 'hidden' }}>
        <LeagueList
          refreshTrigger={refreshTrigger}
          onEdit={handleOpenEditDialog}
          onDelete={handleOpenConfirmDialog}
        />
      </Box>
      {selectedLeague && (
        <EditLeague
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          leagueData={selectedLeague}
          onLeagueUpdated={handleLeagueUpdated}
        />
      )}
      <AddLeague
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        onLeagueAdded={handleLeagueAdded}
      />
      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleDeleteLeague}
        message='Are you sure you want to delete this league?'
      />
    </Box>
  )
}

export default LeagueManager
